import { Action, Reducer } from 'redux';
import { IApplicationState, IAppThunkAction, IAnalyticsAction } from './';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import GiftVoucherService from '../service/giftvouchers';
import { IGiftVoucherConfig } from '../interfaces/IGiftVoucherConfig';
import { ICharge } from '../interfaces/ICharge';
import { IGiftVoucher } from '../interfaces/GiftVouchers/IGiftVoucher';
import { GoogleTagManagerEvents } from '../helpers/googleTagManager';
import Logging from '../service/logging';
import LogRocket from 'logrocket';
import _ from 'lodash';
import BrazeEvents from '../helpers/braze';
import { PurchaseTypes } from './PurchaseTypes';

export enum GiftVoucherStatus {
    Unknown = 0,
    Pending = 1,
    Active = 2,
    Cancelled = 3,
    Refunded = 4,
    Expired = 5,
    Failed = 6,
    Completed = 7,
    Reserved = 8,
}

export interface IGiftVoucherState {
    loading: boolean;
    configLoading: boolean;
    failed: boolean;
    configDetails?: IGiftVoucherConfig;
    charge?: ICharge;
    giftVoucher?: IGiftVoucher;
    status: GiftVoucherStatus;
    selectedFixedAmountButton?: number;
    amount?: number;
    toEmail?: string;
    toFullName?: string;
    fromEmail?: string;
    fromFullName?: string;
    message?: string;
    countryCode?: string;
    callingCode?: string;
    phoneNumber?: string;
    validInfo: boolean;
    selectedGiftVoucherAmountUI?: boolean;
    selectedGiftVoucherDetailsUI?: boolean;
    toRecipientSelected?: boolean;
    emailSubscribed: boolean;
    smsSubscribed: boolean;
}

export const RECORD_TO_EMAIL_ADDRESS: 'EMAIL_FIELD_CHANGED' = 'EMAIL_FIELD_CHANGED';
export const RECORD_TO_FULL_NAME: 'NAME_FIELD_CHANGED' = 'NAME_FIELD_CHANGED';
export const RECORD_MESSAGE: 'MESSAGE_FIELD_CHANGED' = 'MESSAGE_FIELD_CHANGED';
export const RECORD_PHONE_NUMBER: 'RECORD_PHONE_NUMBER' = 'RECORD_PHONE_NUMBER';

export const RECORD_FROM_EMAIL_ADDRESS: 'RECORD_FROM_EMAIL_ADDRESS' = 'RECORD_FROM_EMAIL_ADDRESS';
export const RECORD_FROM_FULL_NAME: 'RECORD_FROM_FULL_NAME' = 'RECORD_FROM_FULL_NAME';

export const RECORD_EMAIL_SUBSCRIPTION: 'RECORD_EMAIL_SUBSCRIPTION' = 'RECORD_EMAIL_SUBSCRIPTION';
export const RECORD_SMS_SUBSCRIPTION: 'RECORD_SMS_SUBSCRIPTION' = 'RECORD_SMS_SUBSCRIPTION';

export const AMOUNT_ENTERED: 'CUSTOM_AMOUNT_ENTERED' = 'CUSTOM_AMOUNT_ENTERED';
export const BUTTON_SELECTED: 'BUTTON_SELECTED' = 'BUTTON_SELECTED';
export const CLEAR_FIELD: 'CLEAR_FIELD' = 'CLEAR_FIELD';

export const GIFT_VOUCHER_CONFIG_LOADING: 'GIFT_VOUCHER_CONFIG_LOADING' = 'GIFT_VOUCHER_CONFIG_LOADING';
export const GIFT_VOUCHER_CONFIG_LOADED: 'GIFT_VOUCHER_CONFIG_LOADED' = 'GIFT_VOUCHER_CONFIG_LOADED';

export const RECIPIENT_SELECTED: 'RECIPIENT_SELECTED' = 'RECIPIENT_SELECTED';

export const RESERVE_GIFT_VOUCHER_SUCCESS: 'RESERVE_GIFT_VOUCHER_SUCCESS' = 'RESERVE_GIFT_VOUCHER_SUCCESS';
export const RESERVE_GIFT_VOUCHER_FAILED: 'RESERVE_GIFT_VOUCHER_FAILED' = 'RESERVE_GIFT_VOUCHER_FAILED';
export const RESERVE_GIFT_VOUCHER_REQUESTED: 'RESERVE_GIFT_VOUCHER_REQUESTED' = 'RESERVE_GIFT_VOUCHER_REQUESTED';

export const VALIDATE_GIFT_VOUCHER: 'VALIDATE_GIFT_VOUCHER' = 'VALIDATE_GIFT_VOUCHER';

export const UPDATE_GIFT_VOUCHER_AMOUNT_SUCCESS: 'UPDATE_GIFT_VOUCHER_AMOUNT_SUCCESS' = 'UPDATE_GIFT_VOUCHER_AMOUNT_SUCCESS';
export const UPDATE_GIFT_VOUCHER_AMOUNT_FAILED: 'UPDATE_GIFT_VOUCHER_AMOUNT_FAILED' = 'UPDATE_GIFT_VOUCHER_AMOUNT_FAILED';
export const UPDATE_GIFT_VOUCHER_AMOUNT_LOADING: 'UPDATE_GIFT_VOUCHER_AMOUNT_LOADING' = 'UPDATE_GIFT_VOUCHER_AMOUNT_LOADING';

export const MODIFY_GIFT_VOUCHER: 'MODIFY_GIFT_VOUCHER' = 'MODIFY_GIFT_VOUCHER';

export const CONFIRM_GIFT_VOUCHER_SUCCESS: 'CONFIRM_GIFT_VOUCHER_SUCCESS' = 'CONFIRM_GIFT_VOUCHER_SUCCESS';
export const CONFIRM_GIFT_VOUCHER_FAILED: 'CONFIRM_GIFT_VOUCHER_FAILED' = 'CONFIRM_GIFT_VOUCHER_FAILED';
export const CONFIRM_GIFT_VOUCHER_COMPLETE: 'CONFIRM_GIFT_VOUCHER_COMPLETE' = 'CONFIRM_GIFT_VOUCHER_COMPLETE';
export const CONFIRM_GIFT_VOUCHER_REQUESTED: 'CONFIRM_GIFT_VOUCHER_REQUESTED' = 'CONFIRM_GIFT_VOUCHER_REQUESTED';

export const CANCEL_GIFT_VOUCHER_SUCCESS: 'CANCEL_GIFT_VOUCHER_SUCCESS' = 'CANCEL_GIFT_VOUCHER_SUCCESS';
export const CANCEL_GIFT_VOUCHER_FAILED: 'CANCEL_GIFT_VOUCHER_FAILED' = 'CANCEL_GIFT_VOUCHER_FAILED';
export const CANCEL_GIFT_VOUCHER_COMPLETE: 'CANCEL_GIFT_VOUCHER_COMPLETE' = 'CANCEL_GIFT_VOUCHER_COMPLETE';
export const CANCEL_GIFT_VOUCHER_REQUESTED: 'CANCEL_GIFT_VOUCHER_REQUESTED' = 'CANCEL_GIFT_VOUCHER_REQUESTED';

export const GIFT_VOUCHER_DETAILS_LOADED: 'GIFT_VOUCHER_DETAILS_LOADED' = 'GIFT_VOUCHER_DETAILS_LOADED';

export const CLEAR_GIFT_VOUCHER: 'CLEAR_GIFT_VOUCHER' = 'CLEAR_GIFT_VOUCHER';

export const CHANGE_USER: 'CHANGE_USER' = 'CHANGE_USER';

export interface IToEmailFieldChangedAction extends IAnalyticsAction {
    type: typeof RECORD_TO_EMAIL_ADDRESS;
    email: string;
}

export interface IToNameFieldChangedAction extends IAnalyticsAction {
    type: typeof RECORD_TO_FULL_NAME;
    name: string;
}

export interface IFromEmailFieldChangedAction extends IAnalyticsAction {
    type: typeof RECORD_FROM_EMAIL_ADDRESS;
    email: string;
}

export interface IFromNameFieldChangedAction extends IAnalyticsAction {
    type: typeof RECORD_FROM_FULL_NAME;
    name: string;
}

export interface IMessageFieldChangedAction extends IAnalyticsAction {
    type: typeof RECORD_MESSAGE;
    message: string;
}

export interface IPhoneNumberFieldChangedAction {
    type: typeof RECORD_PHONE_NUMBER;
    phoneNumber: string;
    countryCode: string;
    callingCode?: string;
}

export interface IAmountEnteredAction extends IAnalyticsAction {
    type: typeof AMOUNT_ENTERED;
    amount: number;
}

export interface IButtonSelectedAction extends IAnalyticsAction {
    type: typeof BUTTON_SELECTED;
    number: number;
    amount: number;
}

export interface IEmailSubscriptionAction {
    type: typeof RECORD_EMAIL_SUBSCRIPTION;
    isSubscribed: boolean;
}

export interface ISmsSubscriptionAction  {
    type: typeof RECORD_SMS_SUBSCRIPTION;
    isSubscribed: boolean;
}

export interface IClearFieldAction {
    type: typeof CLEAR_FIELD;
    amount: number;
}

export interface IGiftVoucherConfigLoadingAction {
    type: typeof GIFT_VOUCHER_CONFIG_LOADING;
}
export interface IGiftVoucherConfigLoadedAction {
    type: typeof GIFT_VOUCHER_CONFIG_LOADED;
    details?: IGiftVoucherConfig;
    success: boolean;
}

export interface IReserveGiftVoucherSuccessAction extends IAnalyticsAction {
    type: typeof RESERVE_GIFT_VOUCHER_SUCCESS;
    giftVoucher: IGiftVoucher;
    charge: ICharge;
}

export interface IReserveGiftVoucherFailedAction {
    type: typeof RESERVE_GIFT_VOUCHER_FAILED;
    failureMessageKey: string;
}

export interface IReserveGiftVoucherRequestedAction {
    type: typeof RESERVE_GIFT_VOUCHER_REQUESTED;
}

export interface IRecipientSelectedAction {
    type: typeof RECIPIENT_SELECTED;
    toRecipient: boolean;
}

export interface IValidateGiftVoucherAction {
    type: typeof VALIDATE_GIFT_VOUCHER;
    valid: boolean;
}

export interface IUpdateGiftVoucherAmountSuccessAction {
    type: typeof UPDATE_GIFT_VOUCHER_AMOUNT_SUCCESS;
    giftVoucher: IGiftVoucher;
    charge: ICharge;
}

export interface IUpdateGiftVoucherAmountFailedAction {
    type: typeof UPDATE_GIFT_VOUCHER_AMOUNT_FAILED;
    failureMessageKey: string;
}

export interface IUpdateGiftVoucherAmountLoadingAction {
    type: typeof UPDATE_GIFT_VOUCHER_AMOUNT_LOADING;
}

export interface IConfirmGiftVoucherFailedAction {
    type: typeof CONFIRM_GIFT_VOUCHER_FAILED;
}

export interface IConfirmGiftVoucherSuccessAction extends IAnalyticsAction {
    type: typeof CONFIRM_GIFT_VOUCHER_SUCCESS;
}

export interface IConfirmGiftVoucherCompletedAction {
    type: typeof CONFIRM_GIFT_VOUCHER_COMPLETE;
}

export interface IConfirmGiftVoucherRequestedAction {
    type: typeof CONFIRM_GIFT_VOUCHER_REQUESTED;
}

export interface ICancelGiftVoucherSuccessAction {
    type: typeof CANCEL_GIFT_VOUCHER_SUCCESS;
}

export interface ICancelGiftVoucherFailedAction {
    type: typeof CANCEL_GIFT_VOUCHER_FAILED;
}

export interface ICancelGiftVoucherCompleteAction {
    type: typeof CANCEL_GIFT_VOUCHER_COMPLETE;
}

export interface ICancelGiftVoucherRequestedAction {
    type: typeof CANCEL_GIFT_VOUCHER_REQUESTED;
}

export interface IGiftVoucherDetailsLoadedAction {
    type: typeof GIFT_VOUCHER_DETAILS_LOADED;
    success: boolean;
    details?: IGiftVoucher;
}

export interface IModifyGiftVoucherAction extends IAnalyticsAction {
    type: typeof MODIFY_GIFT_VOUCHER;
}

export interface IClearGiftVoucherAction {
    type: typeof CLEAR_GIFT_VOUCHER;
}

export interface IChangeUserAction extends IAnalyticsAction {
    type: typeof CHANGE_USER;
    giftVoucher: IGiftVoucher;
}


const undefinedState: IGiftVoucherState = {
    loading: false,
    configLoading: false,
    failed: false,
    configDetails: undefined,
    charge: undefined,
    giftVoucher: undefined,
    status: GiftVoucherStatus.Unknown,
    selectedFixedAmountButton: undefined,
    amount: undefined,
    toEmail: undefined,
    toFullName: undefined,
    message: undefined,
    fromEmail: undefined,
    fromFullName: undefined,
    selectedGiftVoucherAmountUI: false,
    selectedGiftVoucherDetailsUI: false,
    toRecipientSelected: undefined,
    validInfo: false,
    emailSubscribed: false,
    smsSubscribed: false,
}

export type KnownGiftVoucherActions =
    | IToEmailFieldChangedAction
    | IToNameFieldChangedAction
    | IFromEmailFieldChangedAction
    | IFromNameFieldChangedAction
    | IMessageFieldChangedAction
    | IAmountEnteredAction
    | IPhoneNumberFieldChangedAction
    | IButtonSelectedAction
    | IEmailSubscriptionAction
    | ISmsSubscriptionAction
    | IClearFieldAction
    | IGiftVoucherConfigLoadingAction
    | IGiftVoucherConfigLoadedAction
    | IRecipientSelectedAction
    | IReserveGiftVoucherSuccessAction
    | IReserveGiftVoucherFailedAction
    | IReserveGiftVoucherRequestedAction
    | IValidateGiftVoucherAction
    | IUpdateGiftVoucherAmountSuccessAction
    | IUpdateGiftVoucherAmountFailedAction
    | IUpdateGiftVoucherAmountLoadingAction
    | IModifyGiftVoucherAction
    | IConfirmGiftVoucherFailedAction
    | IConfirmGiftVoucherSuccessAction
    | IConfirmGiftVoucherCompletedAction
    | IConfirmGiftVoucherRequestedAction
    | ICancelGiftVoucherSuccessAction
    | ICancelGiftVoucherFailedAction
    | ICancelGiftVoucherCompleteAction
    | ICancelGiftVoucherRequestedAction
    | IClearGiftVoucherAction
    | IGiftVoucherDetailsLoadedAction
    | IChangeUserAction
    | CallHistoryMethodAction;

export const actionCreators = {

    continuePendingGiftVoucher: (): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {

        const appState = getState();
        const isEventBooking = false;
        const charge = appState?.booking?.charge;
        const selectedSite = appState?.sites?.selectedSite;
        const currentLocale = appState?.intl?.currentLocale;
        const selectedPurchaseType = PurchaseTypes[appState?.purchaseType?.selectedPurchaseType].toString();

        dispatch(push(`/checkout/${selectedSite?.urlKey}`));
        if (charge && selectedSite && currentLocale && selectedPurchaseType) {

            const isReservation = appState?.sites?.selectedSite?.reservationPortal ?? false;
            const isGiftVoucher = appState?.purchaseType?.selectedPurchaseType === PurchaseTypes.GiftVoucher;

            BrazeEvents.CheckoutInitiated(
                isEventBooking,
                charge,
                selectedSite,
                currentLocale,
                isReservation,
                isGiftVoucher,
                "N/A"
            )
        }
    },

    reserveGiftVoucher: (siteId: number, adBlockEnabled: boolean): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        const giftVoucher = appState.giftVoucher;

        if (giftVoucher) {
            const emailAddress = appState?.giftVoucher?.fromFullName;
            const name = appState?.giftVoucher?.fromFullName;
            const isEventBooking = false;
            const selectedSite = appState?.sites?.selectedSite;
            const currentLocale = appState?.intl?.currentLocale;
            const selectedPurchaseType = PurchaseTypes[appState?.purchaseType?.selectedPurchaseType].toString();

            const isReservation = appState?.sites?.selectedSite?.reservationPortal ?? false;
            const isGiftVoucher = appState?.purchaseType?.selectedPurchaseType === PurchaseTypes.GiftVoucher;

            const expirationFromConfig = appState.giftVoucher.configDetails?.expiryYears;

            let expirationDate = undefined

            if (expirationFromConfig != undefined) {
                expirationDate = new Date();
                expirationDate.setFullYear(expirationDate.getFullYear() + expirationFromConfig);
                expirationDate = expirationDate.toISOString();
            }

            if (selectedSite && currentLocale && selectedPurchaseType) {
                GiftVoucherService.ReserveGiftVoucher(giftVoucher, name, emailAddress, siteId, expirationDate, adBlockEnabled)
                    .then((data) => {
                        dispatch({
                            type: RESERVE_GIFT_VOUCHER_SUCCESS,
                            giftVoucher: data.giftVoucher,
                            charge: data.charge,
                            googleTagManagerEvent: GoogleTagManagerEvents.ReserveGiftVoucher(data.charge),
                            brazeEvent: BrazeEvents.CheckoutInitiated(
                                isEventBooking,
                                data.charge,
                                selectedSite,
                                currentLocale,
                                isReservation,
                                isGiftVoucher,
                                "N/A"
                            ),
                        });
                        Logging.SetPaymentId(data.giftVoucher.paymentId?.toString());
                        dispatch(push(`/checkout/${appState.sites.selectedSite?.urlKey}`));
                    })

                    .catch((error: Error) => {
                        dispatch({
                            type: RESERVE_GIFT_VOUCHER_FAILED,
                            failureMessageKey: 'checkout failed',
                        });
                    });

                dispatch({ type: RESERVE_GIFT_VOUCHER_REQUESTED });
            }
        }
    },

    confirmGiftVoucher: (
        paymentGatewayMetadata: any,
        paymentMethodReference: string,
        paymentMethodTypeId: number,
        adBlockDetected: boolean
    ): IAppThunkAction<any> => (dispatch, getState) => {
        const appState: IApplicationState = getState();

        if (appState && appState.giftVoucher.giftVoucher && appState.giftVoucher.charge) {
            const giftVoucher = appState.giftVoucher.giftVoucher;
            const charge = appState.giftVoucher.charge;

            //move to processing page before starting
            dispatch(push('/processing'));

            GiftVoucherService.ConfirmGiftVoucher(
                charge,
                giftVoucher,
                paymentMethodReference,
                paymentMethodTypeId,
                paymentGatewayMetadata,
                adBlockDetected
            )
                .then((data) => {
                    dispatch(actionCreators.confirmGiftVoucherSuccess());
                })
                .catch((error: Error) => {
                    dispatch({ type: CONFIRM_GIFT_VOUCHER_FAILED });
                });

            dispatch({ type: CONFIRM_GIFT_VOUCHER_COMPLETE });
        }

        dispatch({ type: CONFIRM_GIFT_VOUCHER_REQUESTED });
    },

    confirmGiftVoucherSuccess: (): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        const giftVoucher = appState.giftVoucher.giftVoucher;
        const charge = appState.giftVoucher.charge;

        if (charge && giftVoucher) {

            const siteCode = appState.sites.selectedSite?.code ?? '';
            const gtmKey = appState.sites.selectedSiteGtmKey;

            dispatch({
                type: CONFIRM_GIFT_VOUCHER_SUCCESS,
                googleTagManagerEvent: GoogleTagManagerEvents.ConfirmGiftVoucher(charge, giftVoucher, siteCode, gtmKey),
            });

            dispatch(push(`/complete/${appState.sites.selectedSite?.urlKey}`));
        }
    },

    validateGiftVoucher: (onValidationSuccess: any, onValidationFailed: any): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        const giftVoucher = appState.giftVoucher.giftVoucher;
        const siteId = appState.sites.selectedSite?.siteId;
        if (giftVoucher && siteId) {
            GiftVoucherService.GetDetails(siteId, giftVoucher.giftVoucherRef)
                .then((data) => {
                    //check if database voucher is marked as expired
                    if (data.giftVoucherStatusTypeId !== 5) {
                        onValidationSuccess();
                    } else {
                        onValidationFailed();
                    }
                    dispatch({
                        type: GIFT_VOUCHER_DETAILS_LOADED,
                        success: true,
                        details: data,
                    });
                })
                .catch((exception) => {
                    onValidationFailed();
                });
        }
    },

    cancelGiftVoucher: (): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        const giftVoucher = appState.giftVoucher.giftVoucher;
        const charge = appState.giftVoucher.charge;
        const status = appState.giftVoucher.status;
        if (giftVoucher && status === GiftVoucherStatus.Pending) {
            GiftVoucherService.CancelGiftVoucher(giftVoucher.siteId, giftVoucher, charge)
                .then((response) => {
                    dispatch({ type: CANCEL_GIFT_VOUCHER_SUCCESS });
                    dispatch({ type: CANCEL_GIFT_VOUCHER_COMPLETE });
                })

                .catch((error: Error) => {
                    dispatch({ type: CANCEL_GIFT_VOUCHER_FAILED });
                    dispatch({ type: CANCEL_GIFT_VOUCHER_COMPLETE });
                });
        }

        dispatch({ type: CANCEL_GIFT_VOUCHER_REQUESTED });
    },

    clearGiftVoucher: (): IAppThunkAction<KnownGiftVoucherActions> => (dispatch) => {
        Logging.SetPaymentId("");
        dispatch({ type: CLEAR_GIFT_VOUCHER });
    },

    recordToEmailAddress: (providedEmail: string) =>
    ({
        type: RECORD_TO_EMAIL_ADDRESS,
        email: providedEmail,
        googleTagManagerEvent: GoogleTagManagerEvents.RecordRecipientEmailAddress(),
    } as IToEmailFieldChangedAction),

    recordToFullName: (providedName: string) =>
    ({
        type: RECORD_TO_FULL_NAME,
        name: providedName,
        googleTagManagerEvent: GoogleTagManagerEvents.RecordRecipientFullName(),
    } as IToNameFieldChangedAction),

    recordFromEmailAddress: (providedEmail: string) =>
    ({
        type: RECORD_FROM_EMAIL_ADDRESS,
        email: providedEmail,
        googleTagManagerEvent: GoogleTagManagerEvents.RecordEmailAddress()
    } as IFromEmailFieldChangedAction),

    recordFromFullName: (providedName: string) =>
    ({
        type: RECORD_FROM_FULL_NAME,
        name: providedName,
        googleTagManagerEvent: GoogleTagManagerEvents.RecordFullName()
    } as IFromNameFieldChangedAction),

    recordMessage: (providedMessage: string) =>
    ({
        type: RECORD_MESSAGE,
        message: providedMessage,
        googleTagManagerEvent: GoogleTagManagerEvents.RecordRecipientMessage(),
    } as IMessageFieldChangedAction),

    recordPhoneNumber: (phoneNumber: string, countryCode: string, callingCode: string) =>
    ({
        type: RECORD_PHONE_NUMBER,
        phoneNumber: phoneNumber,
        countryCode: countryCode,
        callingCode: callingCode,

    } as IPhoneNumberFieldChangedAction),

    recordEmailSubscribed: (isSubscribed: boolean): IAppThunkAction<KnownGiftVoucherActions> => (dispatch) => {
        dispatch({
            type: RECORD_EMAIL_SUBSCRIPTION,
            isSubscribed: isSubscribed,
        });
    },

    recordSmsSubscribed: (isSubscribed: boolean): IAppThunkAction<KnownGiftVoucherActions> => (dispatch) => {
        dispatch({
            type: RECORD_SMS_SUBSCRIPTION,
            isSubscribed: isSubscribed,
        });
    },

    enterAmount: (providedAmount: number) =>
    ({
        type: AMOUNT_ENTERED,
        amount: providedAmount,
        googleTagManagerEvent: GoogleTagManagerEvents.CustomGiftVoucherAmountInput(providedAmount),
    } as IAmountEnteredAction),

    buttonSelected: (providedAmount: number, selectedButton: number) =>
    ({
        type: BUTTON_SELECTED,
        amount: providedAmount,
        number: selectedButton,
        googleTagManagerEvent: GoogleTagManagerEvents.FixedGiftVoucherAmountSelected(providedAmount),
    } as IButtonSelectedAction),

    clearField: () =>
    ({
        type: CLEAR_FIELD,
        amount: 0,
    } as IClearFieldAction),

    getGiftVoucherConfig: (siteId: number): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {
        if (siteId !== undefined) {
            GiftVoucherService.GetVoucherConfig(siteId)
                .then((data) => {
                    dispatch({
                        type: GIFT_VOUCHER_CONFIG_LOADED,
                        success: true,
                        details: data as IGiftVoucherConfig,
                    });
                })
                .catch((exception) => {
                    dispatch({
                        type: GIFT_VOUCHER_CONFIG_LOADED,
                        success: false,
                        details: undefined,
                    });
                });
        }

        dispatch({ type: GIFT_VOUCHER_CONFIG_LOADING });
    },

    setRecipient: (toRecipient: boolean) =>
    ({
        type: RECIPIENT_SELECTED,
        toRecipient: toRecipient,
        googleTagManagerEvent: GoogleTagManagerEvents.GiftVoucherRecipientSelected(toRecipient)
    } as IRecipientSelectedAction),

    validGiftVoucher: (valid: boolean): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {
        const appState = getState();

        if (valid && appState.giftVoucher.toEmail) {
            const uid = `${appState.giftVoucher.toEmail}`;
            LogRocket.identify(uid, { emailAddress: appState.giftVoucher.toEmail });
        }

        dispatch({
            type: VALIDATE_GIFT_VOUCHER,
            valid: valid,
        });
    },

    updateGiftVoucherDetails: (onSuccess: any, onFail: any): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();

        const fromEmailAddress = appState?.giftVoucher?.fromEmail;
        const fromFullName = appState.giftVoucher.fromFullName;
        const toEmailAddress = appState.giftVoucher.toEmail;
        const toFullName = appState.giftVoucher.toFullName;
        const message = appState.giftVoucher.message;
        const toRecipient = appState.giftVoucher.toRecipientSelected;

        const finalToEmail = toRecipient ? toEmailAddress : fromEmailAddress;
        const finalToName = toRecipient ? toFullName : fromFullName;

        let phoneNumber: string | null = null;
        const callingCode = appState.giftVoucher.callingCode;
        if (appState.giftVoucher.phoneNumber) {
            phoneNumber = callingCode + ' ' + appState.giftVoucher.phoneNumber;
        }

        if (appState && appState.giftVoucher.giftVoucher && fromEmailAddress) {
            const giftVoucher = appState.giftVoucher.giftVoucher;
            GiftVoucherService.UpdateGiftVoucher(giftVoucher, fromEmailAddress, fromFullName, finalToEmail, finalToName, message, null)
                .then((data: any) => {
                    onSuccess();
                })
                .catch((error: Error) => {
                    onFail();
                });
        }
    },


    changeUser: (): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();

        const fromEmailAddress = appState?.giftVoucher?.fromEmail;
        const fromFullName = appState.giftVoucher.fromFullName;
        const toEmailAddress = appState.giftVoucher.toEmail;
        const toFullName = appState.giftVoucher.toFullName;
        const message = appState.giftVoucher.message;
        const toRecipient = appState.giftVoucher.toRecipientSelected;

        const finalToEmail = toRecipient ? toEmailAddress : fromEmailAddress;
        const finalToName = toRecipient ? toFullName : fromFullName;

        const venueId = appState?.sites?.selectedSite?.siteId;
        const venueName = appState?.sites?.selectedSite?.name ?? "N/A";
        const names = fromFullName?.split(" ") ?? [];
        const firstName = names[0] ?? "";
        const lastName = names.slice(1).join(" ") ?? "";

        let phoneNumber: string | null = null;
        const callingCode = appState.giftVoucher.callingCode;
        if (appState.giftVoucher.phoneNumber) {
            phoneNumber = callingCode + ' ' + appState.giftVoucher.phoneNumber;
        }

        const selectedSite = appState.sites.selectedSite;
        const currentLocale = appState.intl.currentLocale;

        if (appState && appState.giftVoucher.giftVoucher && fromEmailAddress && venueId && selectedSite) {
            const giftVoucher = appState.giftVoucher.giftVoucher;
            GiftVoucherService.UpdateGiftVoucher(giftVoucher, fromEmailAddress, fromFullName, finalToEmail, finalToName, message, null, phoneNumber)
                .then((data: any) => {
                    dispatch({
                        type: CHANGE_USER,
                        giftVoucher: data.giftVoucher,
                        brazeEvent: BrazeEvents.ChangeUser(
                            data.giftVoucher.playerGuid,
                            fromEmailAddress,
                            firstName,
                            lastName,
                            venueId,
                            venueName,
                            phoneNumber,
                            selectedSite,
                            currentLocale)
                    });
                   
                });
        }
    },

    updateGiftVoucherAmount: (onComplete: () => void): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        const amount = appState.giftVoucher.amount;

        if (appState && appState.giftVoucher.giftVoucher && amount) {
            const giftVoucher = appState.giftVoucher.giftVoucher;
            GiftVoucherService.UpdateGiftVoucher(giftVoucher, null, null, null, null, null, amount)
                .then((data) => {
                    dispatch({
                        type: UPDATE_GIFT_VOUCHER_AMOUNT_SUCCESS,
                        giftVoucher: data.giftVoucher,
                        charge: data.charge,
                    });
                    onComplete();
                })
                .catch((error: Error) => {
                    dispatch({
                        type: UPDATE_GIFT_VOUCHER_AMOUNT_FAILED,
                        failureMessageKey: 'update failed',
                    });
                });
            dispatch({
                type: UPDATE_GIFT_VOUCHER_AMOUNT_LOADING,
            });
        }
    },

    getGiftVoucherDetails: (siteId: number, giftVoucherRef: string): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {
        if (giftVoucherRef) {
            GiftVoucherService.GetDetails(siteId, giftVoucherRef)
                .then((data) => {
                    const appState = getState();
                    if (!_.isEqual(appState.giftVoucher.giftVoucher, data)) {
                        dispatch({
                            type: GIFT_VOUCHER_DETAILS_LOADED,
                            success: true,
                            details: data,
                        });
                    }
                })
                .catch((exception) => {
                    dispatch({
                        type: GIFT_VOUCHER_DETAILS_LOADED,
                        success: false,
                        details: undefined,
                    });
                });
        }
    },

    modifyGiftVoucher: (giftVoucher: IGiftVoucher): IAppThunkAction<KnownGiftVoucherActions> => (dispatch, getState) => {
        const appState = getState();

        dispatch({
            type: MODIFY_GIFT_VOUCHER,
            googleTagManagerEvent: GoogleTagManagerEvents.ModifiedGiftVoucher(giftVoucher),
            brazeEvent: BrazeEvents.ModifyCart(),
        } as IModifyGiftVoucherAction);
        dispatch(push(`/gift-voucher/${appState.sites.selectedSite?.urlKey}`));
    },
};

export const reducer: Reducer<IGiftVoucherState> = (
    state: IGiftVoucherState | undefined,
    incomingAction: Action
): IGiftVoucherState => {
    const action: KnownGiftVoucherActions = incomingAction as KnownGiftVoucherActions;
    if (state === undefined) return undefinedState;

    switch (action.type) {
        case GIFT_VOUCHER_DETAILS_LOADED:
            {
                var giftVoucherStatus = state.status;
                if (action.details && action.details.giftVoucherStatusTypeId !== undefined) {
                    if (action.details.giftVoucherStatusTypeId in GiftVoucherStatus) {
                        giftVoucherStatus = action.details.giftVoucherStatusTypeId as GiftVoucherStatus;
                    }
                }
                return {
                    ...state,
                    loading: false,
                    failed: !action.success,
                    giftVoucher: action.details,
                    status: giftVoucherStatus,
                };
            }
        case GIFT_VOUCHER_CONFIG_LOADING: {
            return {
                ...state,
                loading: false,
                configLoading: true,
                failed: false,
                configDetails: undefined,
            };
        }
        case GIFT_VOUCHER_CONFIG_LOADED: {
            return {
                ...state,
                loading: false,
                configLoading: false,
                failed: !action.success,
                configDetails: action.details,
            };
        }

        case RECORD_TO_EMAIL_ADDRESS: {
            return {
                ...state,
                toEmail: action.email,
                selectedGiftVoucherAmountUI: true,
            }
        }

        case RECORD_TO_FULL_NAME: {
            return {
                ...state,
                toFullName: action.name,
                selectedGiftVoucherAmountUI: true,
            }
        }

        case RECORD_FROM_EMAIL_ADDRESS: {
            return {
                ...state,
                fromEmail: action.email,
                selectedGiftVoucherAmountUI: true,
            }
        }

        case RECORD_FROM_FULL_NAME: {
            return {
                ...state,
                fromFullName: action.name,
                selectedGiftVoucherAmountUI: true,
            }
        }

        case RECORD_MESSAGE: {
            return {
                ...state,
                message: action.message,
                selectedGiftVoucherAmountUI: true,
            }
        }

        case RECORD_EMAIL_SUBSCRIPTION: {
            return {
                ...state,
                emailSubscribed: action.isSubscribed
            }
        }
        case RECORD_SMS_SUBSCRIPTION: {
            return {
                ...state,
                smsSubscribed: action.isSubscribed
            }
        }

        case RECORD_PHONE_NUMBER: {
            return {
                ...state,
                phoneNumber: action.phoneNumber,
                countryCode: action.countryCode,
                callingCode: action.callingCode,
            }
        }

        case AMOUNT_ENTERED: {
            return {
                ...state,
                selectedFixedAmountButton: 0,
                amount: action.amount,
                selectedGiftVoucherAmountUI: true,
            }
        }

        case BUTTON_SELECTED: {
            return {
                ...state,
                selectedFixedAmountButton: action.number,
                amount: action.amount,
                selectedGiftVoucherAmountUI: true,
            }
        }

        case CLEAR_FIELD: {
            return {
                ...state,
                amount: 0,
                selectedGiftVoucherAmountUI: true,
            }
        }

        case RECIPIENT_SELECTED: {
            return {
                ...state,
                toRecipientSelected: action.toRecipient,
            }
        }

        case RESERVE_GIFT_VOUCHER_SUCCESS: {
            return {
                ...state,
                loading: false,
                failed: false,
                charge: action.charge,
                giftVoucher: action.giftVoucher,
                status: GiftVoucherStatus.Pending,
            }
        }

        case RESERVE_GIFT_VOUCHER_FAILED: {
            return {
                ...state,
                loading: false,
                failed: true,
                charge: undefined,
                giftVoucher: undefined,
            }
        }

        case RESERVE_GIFT_VOUCHER_REQUESTED: {
            return {
                ...state,
                loading: true,
                failed: false,
                charge: undefined,
                giftVoucher: undefined,
            }
        }

        case VALIDATE_GIFT_VOUCHER: {
            return {
                ...state,
                loading: false,
                failed: false,
                validInfo: action.valid,
            }
        }

        case UPDATE_GIFT_VOUCHER_AMOUNT_SUCCESS: {
            return {
                ...state,
                charge: action.charge,
                giftVoucher: action.giftVoucher,
            }
        }

        case UPDATE_GIFT_VOUCHER_AMOUNT_FAILED: {
            return {
                ...state,
                loading: false,
                failed: true,
            }
        }

        case UPDATE_GIFT_VOUCHER_AMOUNT_LOADING: {
            return {
                ...state,
                loading: true,
                failed: false,
            }
        }

        case CANCEL_GIFT_VOUCHER_REQUESTED: {
            return {
                ...state,
                loading: true,
                failed: false,
            };
        }

        case CANCEL_GIFT_VOUCHER_SUCCESS: {
            return {
                ...state,
                loading: false,
                failed: false,
                giftVoucher: undefined,
                charge: undefined,
                status: GiftVoucherStatus.Unknown,
                selectedFixedAmountButton: undefined,
                amount: undefined,
                toEmail: undefined,
                toFullName: undefined,
                fromEmail: undefined,
                fromFullName: undefined,
                message: undefined,
                smsSubscribed: false,
                emailSubscribed: false,
            };
        }
        case CANCEL_GIFT_VOUCHER_COMPLETE: {
            return {
                ...state,
                loading: false,
                failed: state.failed,
                giftVoucher: undefined,
                charge: undefined,
                status: GiftVoucherStatus.Unknown,
                selectedFixedAmountButton: undefined,
                amount: undefined,
                toEmail: undefined,
                toFullName: undefined,
                fromEmail: undefined,
                fromFullName: undefined,
                message: undefined,
                smsSubscribed: false,
                emailSubscribed: false,
            };
        }
        case CONFIRM_GIFT_VOUCHER_REQUESTED: {
            return {
                ...state,
                loading: true,
                failed: false,
                status: GiftVoucherStatus.Completed,
            };
        }
        case CONFIRM_GIFT_VOUCHER_FAILED: {
            return {
                ...state,
                loading: false,
                failed: true,
                status: GiftVoucherStatus.Completed,
            };
        }
        case CONFIRM_GIFT_VOUCHER_SUCCESS: {
            return {
                ...state,
                loading: false,
                failed: false,
                status: GiftVoucherStatus.Completed,
            };
        }
        case CLEAR_GIFT_VOUCHER: {
            return {
                ...state,
                ...undefinedState,
                configDetails: state.configDetails
            };
        }
        case CHANGE_USER: {
            return {
                ...state,
                giftVoucher: action.giftVoucher,
            }
        }

        default:
            return state;
    }
};
